import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import * as PropTypes from 'prop-types';

import CoursesAll from 'components/Courses/CoursesAll';

import AmbassadorCard from 'pages/AmbassadorPage/ambassadorCard';

import ApiManager from 'ApiManager';

const Api = new ApiManager();

const Ambassador = (props) => {
    const { user, options } = props;
    const [isLoaded, setIsLoaded] = useState(false);
    const [title, setTitle] = useState('');
    const [content, setContent] = useState('');

    const postContent = () => {
        return { __html: content };
    };

    useEffect(() => {
        Api.getAmbassadorPageContent()
            .then((response) => {
                setTitle(response.data.post_title ?? '');
                setContent(response.data.post_content ?? '');
                setIsLoaded(true);
            })
            .catch((error) => {
                setIsLoaded(true);
                console.log('ERROR [getAmbassadorPageContent]', error);
            });
    }, []);

    return (
        <div className="page-ambassador-wrapper">
            <h1>
                {!isLoaded ? (
                    <>Laster...</>
                ) : (
                    <>
                        {title
                            ? title
                            : 'Bli en del av Partnerpådriverprogrammet'}
                    </>
                )}
            </h1>
            <AmbassadorCard title="" text="" options={{}} />
            <CoursesAll limit={['Webinar', 'Opplæring', 'Studietur']} />

            {!isLoaded ? (
                <div className="text-wrapper">
                    <p>Laster...</p>
                </div>
            ) : (
                <>
                    {content ? (
                        <div
                            className="text-wrapper"
                            dangerouslySetInnerHTML={postContent()}
                        />
                    ) : (
                        <div className="text-wrapper">
                            <p>Finner ikke noe innhold.</p>
                        </div>
                    )}
                </>
            )}
        </div>
    );
};

const mapStateToProps = (state, ownProps) => {
    return {
        ...{},
        ...state.user,
        ...state.global,
        ...ownProps,
    };
};

Ambassador.propTypes = {
    user: PropTypes.object.isRequired,
    options: PropTypes.object.isRequired,
};

export default connect(mapStateToProps)(Ambassador);
